<!-- @component Tracks page views and events, integrating with GA, Sentry, and Segment. -->

<script lang="ts">
  import { afterNavigate } from "$app/navigation";

  import { onMount } from "svelte";

  import { analytics } from "$lib/analytics";

  // Google Analytics
  function gtag(..._: any[]) {
    const w = window as any;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(arguments);
  }
  onMount(() => {
    gtag("js", new Date());
    gtag("config", "G-GRVB0ZLLD8");

    // Track UTM tags
    const urlParams = new URLSearchParams(location.search);
    const utmSource = urlParams.get("utm_source");
    if (utmSource) {
      localStorage.setItem("utm-source", utmSource);
    }
  });
  afterNavigate(({ to }) => {
    gtag("set", "page_path", to?.url?.pathname);
    gtag("event", "page_view");
  });

  // Segment and PostHog
  onMount(async () => {
    analytics.load({
      writeKey: "adVkYFLBEV3XP82TZeK7mTcJKBLfbUIH",
    });

    // Load Posthog asynchronously to avoid increasing bundle size.
    // We may update this if using Posthog feature flags.
    await Promise.all([
      import("posthog-js/dist/recorder" as any),
      import("posthog-js/dist/surveys" as any),
      import("posthog-js/dist/exception-autocapture" as any),
      import("posthog-js/dist/tracing-headers" as any),
      import("posthog-js/dist/web-vitals" as any),
    ]);
    const posthog = (await import("posthog-js/dist/module.no-external"))
      .default;
    posthog.init("phc_kkmXwgjY4ZQBwJ6fQ9Q6DaLLOz1bG44LtZH0rAhg1NJ", {
      api_host: "https://us.i.posthog.com",
      segment: analytics as any,
      person_profiles: "identified_only",
      capture_pageview: false,
      capture_pageleave: true,
    });
  });
  afterNavigate(() => {
    analytics.page();
  });
</script>

<!-- Global site tag (gtag.js) - Google Analytics -->
<svelte:head>
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-GRVB0ZLLD8"
  ></script>
</svelte:head>
