<script lang="ts">
  import "@fontsource/fira-mono/400.css";
  import "@fontsource/fira-mono/500.css";
  import "@fontsource/fira-mono/700.css";

  import "../app.css";
  import "../ansi.css";

  import { page } from "$app/stores";

  import { Toaster } from "svelte-sonner";

  import Analytics from "$lib/ui/common/Analytics.svelte";
  import Seo from "$lib/ui/common/Seo.svelte";

  $: title = $page.data.title || "Modal";
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<Seo
  title={$page.data.ogTitle}
  description={$page.data.ogDescription}
  imageUrl={$page.data.ogImageUrl}
/>

{#if $page.url.host === "modal.com"}
  <Analytics />
{/if}

<Toaster />

<slot />
